<template>
  <Spinner v-if="isLoading" />

  <div v-else-if="material">

    <VButtonPageBack class="mb-30" />

    <h1 class="mb-16">{{ material.name }}</h1>

    <InfoTextBlock
      v-if="material.vendor_code"
      labelText="Артикул"
      :text="material.vendor_code"
      class="mb-30"
    />
    <InfoTextBlock
      v-if="material.density"
      labelText="Плотность"
      :text="material.density"
      class="mb-30"
    />
    <VTable
      v-if="!isMobile"
      :columns="columns"
      :items="items"
    />
    <MaterialDetailedCard
      v-else
      :rows="columns"
      :items="material" />
  </div>
</template>

<script>
import InfoTextBlock from '@/components/ui/AppInfoTextBlock'
import VTable from '@/components/ui/AppTable'
import VButtonPageBack from '@/components/simple/button/VButtonPageBack'
import { getById } from '@/api/request'
import { error } from '@/utils/error'
import { createColOrderLinks } from '@/const/table/columns'
import { MATERIAL_URL } from '@/api/handbook/warehouse/Material/MaterialApi'
import MaterialDetailedCard from '@/components/warehouse/material/MaterialDetailedCard.vue'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'
import { mapState } from 'vuex'

export default {
  components: {
    MaterialDetailedCard,
    InfoTextBlock,
    VTable,
    VButtonPageBack
  },
  mixins: [mixinIsLoading],
  props: {
    id_material: {
      type: [Number, String]
    }
  },

  data () {
    return {
      material: {},
      columns: [
        {
          title: 'Диаметр',
          name: 'diameter'
        },
        {
          title: 'Резерв',
          name: 'amount_reserved'
        },
        {
          title: 'Где используется',
          name: 'orders',
          ...createColOrderLinks()
        },
        {
          title: 'Всего на складе',
          name: 'amount'
        },
        {
          title: 'Остаток',
          name: 'balance'
        }
      ],
      items: [],
      url: MATERIAL_URL
    }
  },

  mounted () {
    const id = parseInt(this.id_material)
    this.getMaterial(id)
  },

  computed: {
  ...mapState('mobile', {
      isMobile: 'isMobile'
    })
  },

  methods: {
    async getMaterial (id) {
      try {
        this.startLoading()
        const res = await getById(this.url, id)

        this.material = res.data
        this.material.balance = this.material.amount - this.material.amount_reserved
        this.material.diameter = this.material.diameter ? this.material.diameter.id : '-'

        this.items = [this.material]
      } catch (e) {
        await this.$store.dispatch('alert/setAlertError', error(e.response.data.message))
      } finally {
        this.finishLoading()
      }
    }
  }
}
</script>
